import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CheckUserTypeComponent } from './check-user-type/check-user-type.component';

import { RequestLinkComponent } from './request-link/request-link.component';
import { InvalidComponent } from './invalid/invalid.component';


const routes: Routes = [
 {path:'',component:CheckUserTypeComponent},
 {path:'request-link',component:RequestLinkComponent},
 {path:'invalid-link',component:InvalidComponent},
  {
    path: 'referee',
    loadChildren: () => import('./referee/referee.module').then(m => m.RefereeModule),
   
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

