import { Injectable } from '@angular/core';
import { ApiMethodService } from './api-method.service';
import { environment } from 'src/environments/environment';
import { HttpHeaders } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import ls from 'localstorage-slim';


@Injectable({
  providedIn: 'root'
})
export class RefCandidateService {

  constructor(private _apiService: ApiMethodService,
    private spinner: NgxSpinnerService) {

  }
  showLoader() {
    this.spinner.show();
  }

  hideLoader() {
    this.spinner.hide();
  }
  getHeaders() {
    const requestId = ls.get('paramsId', { decrypt: true });
    const headers = new HttpHeaders({
      'x-api-key': environment.apiKey,
      'subscriptionId': String(requestId)
    }
    )
    return headers;
  }
  mastersAPIReferee(type: any, collectionName: any, body: any) {
    const headers = this.getHeaders();
    return this._apiService.postRequest(`${environment.refereapiurl}${type}/${collectionName}?source=${environment.source}`, body, headers);
  }

  mastersUpdateRefere(type: any, collectionName: any, id: any, body: any) {
    const headers = this.getHeaders();
    return this._apiService.putRequest(`${environment.refereapiurl}${type}/${collectionName}/${id}?source=${environment.source}`, body, headers);
  }
  mastersGetAPIReferee(collectionName: any, id: any) {
    const headers = this.getHeaders();
    return this._apiService.getRequest(`${environment.refereapiurl}${collectionName}/${id}?source=${environment.source}`, headers);
  }
  updateStatusReferee(collectionName: any, body: any) {
    const headers = this.getHeaders();
    return this._apiService.putRequest(`${environment.refereapiurl}${collectionName}?source=${environment.source}`, body, headers);
  }


}
