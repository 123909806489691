import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppMaterialModule } from './material/material.module';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { RefereeComponent } from './referee/referee.component';
import { CheckUserTypeComponent } from './check-user-type/check-user-type.component';
import { HttpClientModule } from '@angular/common/http';
import { NgxSpinnerModule } from "ngx-spinner";
import { RequestLinkComponent } from './request-link/request-link.component';
import { InvalidComponent } from './invalid/invalid.component';


@NgModule({
  declarations: [
    AppComponent,
    ToolbarComponent,
    RefereeComponent,
    CheckUserTypeComponent,
    RequestLinkComponent,
    InvalidComponent,

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AppMaterialModule,
    HttpClientModule,
    NgxSpinnerModule
  ],
  providers: [],
  bootstrap: [AppComponent],
  schemas:[CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
