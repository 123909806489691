import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import ls from 'localstorage-slim';

@Component({
  selector: 'app-check-user-type',
  templateUrl: './check-user-type.component.html',
  styleUrls: ['./check-user-type.component.scss']
})
export class CheckUserTypeComponent implements OnInit {

  constructor(private _router: Router
  ) {

  }

  ngOnInit(): void {
    const url = window.location.href.split('/?t');
    if (url.length > 0) {
      if (url.length === 1) {
        this._router.navigate(['invalid-link']);
      }
      else {
        const codeUrl = url[1].split('=');
        const paramsId = codeUrl[1];
        ls.set('paramsId', paramsId, { encrypt: true });
        this._router.navigate(['referee']);
      }

    }
  }

}
