import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';



@Injectable({
    providedIn: 'root'
})
export class ApiMethodService {

    constructor(
        private _httpClient: HttpClient,
        private _router: Router,
        private spinner: NgxSpinnerService
    ) {

    }
    postRequest(url: string, body: any, headers?: HttpHeaders) {

        let request;

        if (headers) {

            request = this._httpClient.post(url, body, { headers });
        }
        else {
            request = this._httpClient.post(url, body);
        }
        return request.toPromise().then(res => {
            return res;
        })
            .catch(err => {
              console.log(err);
                this.handleError(err);
            });
    }
    getRequest(url: string, headers?: HttpHeaders) {
        let request;

        if (headers) {
            request = this._httpClient.get(url, { headers });
        }
        else {
            request = this._httpClient.get(url);
        }
        return request.toPromise().then(res => {
            return res;
        }).catch(err => {
           
        });
    }
    putRequest(url: string, body: any, headers?: HttpHeaders) {
        let request;

        if (headers) {
            request = this._httpClient.put(url, body, { headers });
        }
        else {
            request = this._httpClient.put(url, body);
        }
        return request.toPromise().then(res => {
            return res;
        }).catch(err => {

        });
    }
    hideLoader() {
        this.spinner.hide();
    }
    handleError(err: any) {
        if (err && err.error.data.message === 'key expired please generate new key') {
            this.hideLoader();
            this._router.navigate(['request-link']);
        }
        if(err && err.error.data.message === 'Session Expired'){
            this.hideLoader();
            this._router.navigate(['invalid-link']);
        }
    }
}
