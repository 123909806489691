import { Component, OnInit } from '@angular/core';
import { RefCandidateService } from '../service/ref-candidate.service';
import ls from 'localstorage-slim';
@Component({
  selector: 'app-request-link',
  templateUrl: './request-link.component.html',
  styleUrls: ['./request-link.component.scss']
})
export class RequestLinkComponent implements OnInit {

  constructor(private service: RefCandidateService) { }

  ngOnInit(): void {
  }
  async generateKey() {
    await this.service.mastersGetAPIReferee('renewRequest',ls.get('paramsId', { decrypt: true })).then((res: any) => {
      if (res.data.message === 'New Request Created') {
        alert('New Link has Been Send Sucessfully. Please Check Your Email');
      }
    });
  }
}
